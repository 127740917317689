import React, { useEffect, useState } from "react";
import { config } from "../../config/config";
import { userService } from "../../_services";
import { reduxForm } from "redux-form";
// import {config} from '../../config/config'
import Modal from "react-modal";
import StarRatings from "react-star-ratings";

// import {Layout} from '../common/Layout'
// import close_image from '../../assets/images/close.png';
// import profile_image from '../../assets/images/profile_logo.jpg';
import loader_image from "../../assets/images/loader.gif";
// import paymentmodal from '../../assets/images/payment-modal.png';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
// import Button from '@material-ui/core/Button';
// import { DropzoneArea } from 'material-ui-dropzone';
import calender_image from "../../assets/images/calender_image.png";
import download_invoice from "../../assets/images/download_invoice.png";
import fav_heart from "../../assets/images/fav-heart.png";
import unfav_heart from "../../assets/images/unfav-heart.png";
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams,
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import bid_image from '../../assets/images/bid_image.png';
import mastro from "../../assets/images/mastro.png";
// import time from '../../assets/images/time.png';
// import date from '../../assets/images/date.png';
import location_image from "../../assets/images/location_image.png";
import image1 from "../../assets/images/image1.png";
import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/image3.png";
import left_arrow from "../../assets/images/left_arrow.png";
import right_arrow from "../../assets/images/right_arrow.png";
// import user_profile_image from '../../assets/images/user_profile_image.jpg';
// import dollar_image from '../../assets/images/dollar_image.png';
// import { Multiselect } from 'multiselect-react-dropdown';
import "rc-time-picker/assets/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentComponent from "./PaymentComponent";
import { get } from "jquery";
var dateFormat = require("dateformat");
// const stripePromise = loadStripe(
//   "pk_test_51H54EAB80x5swfvw4xQGhJwMZ2vXqnLHS59kGho6u2Zm4vXHF4KM8CFwVSaxQMqfl2cHJGQ2s7FLZDiRXRPiKWMX00HqaWsbV7"
// );
const stripePromise = loadStripe(
  "pk_live_51H54EAB80x5swfvwsR108Fog1f668MEBEaaKsbbbky0wVWu9maqjuZhaR3fr3Cvl2NCKA9qkXr0HFJN7ZXyUyqQh00exEoECBZ"
);

// import TimePicker from 'rc-time-picker';

// interface fieldInterface {
//     input: any;
//     label: string;
//     type: string;
//     image: string;
//     id: string;
//     placeholder: string;
//     meta: {
//         touched: boolean;
//         error: string;
//     };
// }

// interface fieldInterface {
//     input: any;
//     label: string;
//     type: string;
//     image: string;
//     id: string;
//     stratDate: any;
//     handleChange: any;
//     placeholder: string;
//     maxLength: number;
//     meta: {
//         touched: boolean;
//         error: string;
//     };
// }

const customStyles1 = {
  overlay: {
    display: "block",
    paddingRight: "15px",
    backgroundColor: "rgba(51,51,51,0.8)",
    zIndex: 99,
  },

  content: {
    position: "",
    top: "",
    right: "",
    bottom: "",
    left: "",
  },
};

// const renderField = ({ input, label, type, id, placeholder, maxLength, meta: { touched, error } }: fieldInterface) => (
//     <div className="form-group">
//         <label>{label}</label>
//         <div className="input_content">
//             <input {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />
//             {touched && error && <span style={{ color: "red" }}>{error}</span>}
//         </div>
//     </div>
// )

// const renderTextareaField = ({ input, label, type, id, placeholder, maxLength, meta: { touched, error } }: fieldInterface) => (
//     <div className="form-group">
//         <label>{label}</label>
//         <div className="input_content">
//             <input {...input} type={type} maxLength={maxLength} className="form-control" id={id} placeholder={placeholder} />
//             {touched && error && <span style={{ color: "red" }}>{error}</span>}
//         </div>
//     </div>
// )

const Myprofile = (props: any) => {
  const handletransactionDateChange = (date: any) => {
    // formData.dob= date;
    settransactionDate(date);
    setTimeout(function () {
      get_transactions(date);
    }, 100);
  };

  const [docIsLink, setDocIsLink] = useState(true);
  const [docError, setDocError] = useState("");
  const [stratDate, setStartdate] = useState("") as any;
  const [birthday, setBirthday] = useState() as any;
  // const [stratTime, setStarttime] = useState('');
  // const [endDate, setEnddate] = useState('');
  // const [endtime, setEndtime] = useState('');
  // const [selectedValue, setselectedValue] = useState(1);
  const [latlng, setLatlng] = useState() as any;
  const [address, setAddress] = useState("");
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });

  const [helpName, setHelpName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSbuject] = useState("");
  const [message, setMessage] = useState("");

  const [helpNameError, setHelpNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSbujectError] = useState("");
  const [messageError, setMessageError] = useState("");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [CvvError, setCvvError] = useState("");
  const [showDOBError, setDOBError] = useState(false);

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Cvv, setCvv] = useState("");

  const [imagedata, setImagedata] = useState([]);
  const [uploaddoc, setUploaddoc] = useState([]);

  const [hiredmodal, setHiredModal] = useState(false);

  const [name, setName] = useState() as any;
  const [dob, setDob] = useState() as any;
  const [location, setLocation] = useState() as any;
  const [doc, setDoc] = useState() as any;
  const [docName, setDocName] = useState() as any;
  const [doc1, setDoc1] = useState([]) as any;
  const [bio, setBio] = useState() as any;
  const [profileData, setProfileData] = useState() as any;
  const [profileImage, setProfileImage] = useState([]);
  const [NameErr, setNameErr] = useState("");
  const [bioErr, setBioErr] = useState("");
  const [locErr, setLocErr] = useState("");
  const [cards, setcards] = useState([]) as any;
  const [favWorker, setfavWorker] = useState([]) as any;
  const [transactions, settransactions] = useState("") as any;
  let current_date = new Date();
  const [transactionDate, settransactionDate] = useState(current_date) as any;
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;
  const [isrevealPassword, setisrevealPassword] = useState(false);
  const [isrevealConfirmassword, setisrevealConfirmassword] = useState(false);
  const [isrevealNewPassword, setisrevealNewPassword] = useState(false);
  const [toggleStripe, setToggleStripe] = useState(true);
  const [nameHandler, setNameHandler] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (!clientSecret && toggleStripe) {
      const getClientSecretFn = async () => {
        userService
          .getClientSecret()
          .then((result) => {
            setClientSecret(result.data.client_secret);
          })
          .then(() => {
            setToggleStripe(true);
          });
      };

      getClientSecretFn();
    }
  }, [toggleStripe]);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  const submit = (e: any, data: any, type: String) => {
    // e.preventDefault()
    // e.preventDefault();
    let fields = data;
    let errors: any = [];
    let formIsValid = true;

    if (type == "changePassword") {
      if (oldPassword.length == 0) {
        setOldPasswordError("Old Password field can't be empty.");
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      } else if (newPassword.length == 0) {
        setNewPasswordError("New Password field can't be empty.");
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      } else if (newPassword.length < 8) {
        setNewPasswordError("Password length should be greater than 8 digits.");
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      } else if (confirmPassword.length == 0) {
        setConfirmPasswordError("Confirm Password field can't be empty.");
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      } else if (confirmPassword != newPassword) {
        setConfirmPasswordError(
          "Confirm password doesn't match with New Password"
        );
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      } else {
        setLoaderstyle({ display: "block" });
        // hit the server
        const formData = new URLSearchParams();
        formData.append("old_password", oldPassword);
        formData.append("new_password", newPassword);

        userService
          .changePassword(formData.toString())
          .then(function (response) {
            alert(response.data.message);
            setLoaderstyle({ display: "none" });
            localStorage.clear();
            window.location.href = "/";
          })
          .catch(function (error) {
            console.log("errorrrrr", error);
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
          });

        // window.location.href = ""
      }
    } else if (type == "helpAndSupport") {
      if (helpName.length == 0) {
        setHelpNameError("Name field can't be empty.");
        toast.error(config.errorValidationMsg);
        window.scrollTo({
          top: 500,
          left: 0,
          behavior: "smooth",
        });
      } else if (email.length == 0) {
        setEmailError("Email field can't be empty.");
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Entered Email is not valid.");
      } else if (subject.length == 0) {
        setSbujectError("Subject field can't be empty.");
      } else if (message.length == 0) {
        setMessageError("Message field can't be empty.");
      } else {
        // window.location.href = ""

        let params = {
          name: helpName,
          email: email,
          subject: subject,
          message: message,
        };

        setLoaderstyle({ display: "block" });
        userService
          .helpsupports(params)
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            console.log(response.data.message);
            alert(response.data.message);
            window.location.href = "";
          })
          .catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
          });
      }
    } else if (type == "addCard") {
      // if (accountName.length == 0) {
      // 	formIsValid = false;
      // 	setAccountNameError("Card Holder Name field can't be empty.");
      // 	toast.error(config.errorValidationMsg);
      // } else if (accountNumber.length == 0) {
      // 	formIsValid = false;
      // 	setAccountNumberError("Card Number field can't be empty.");
      // 	toast.error(config.errorValidationMsg);
      // } else if (!/^\d+$/.test(accountNumber)) {
      // 	formIsValid = false;
      // 	setAccountNumberError('Card Number should only contains numbers');
      // 	toast.error(config.errorValidationMsg);
      // }

      if (!stratDate) {
        setDOBError(true);
      } else if (!/^\d+$/.test(Cvv)) {
        formIsValid = false;
        setCvvError("CVV field should only contains numbers.");
      } else if (Cvv.length < 3) {
        formIsValid = false;
        setCvvError("CVV must be 3 or 4 digits.");
      } else {
        console.log("stratDate", stratDate);
        let params = {
          card_number: accountNumber,
          exp_month: stratDate.getMonth() + 1,
          exp_year: stratDate.getFullYear(),
          cvc: Cvv,
        };

        setLoaderstyle({ display: "block" });
        userService
          .save_card(params)
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            alert("Card added Successfully.");
            closemodal();
            getcards();
            // window.location.href = "/invite_user/"+job_id;
          })
          .catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
          });
      }
    }
  };
  const privacy_policy = config.apiBaseUrl + "privacy/privacy_policy.html";
  const terms_ondition = config.apiBaseUrl + "terms/TERMS_Conditions.html";

  const UpdateProfileImage = (file: any) => {
    setLoaderstyle({ display: "block" });
    let formdata = new FormData();
    formdata.append("profile_image", file);
    userService
      .updateProfile(formdata)
      .then(function (response) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        alert("Profile updated successfully");
        // getprofiledata();

        window.location.reload();

        // getprofiledata();
      })
      .catch(function (error) {
        userService.handleError(error);
      });
  };
  const UpdateProfile = () => {
    let loc: any = document.getElementById(
      "react-google-places-autocomplete-input"
    );
    let myloc: any = loc.value;

    if (name == "") {
      setNameErr("Name field can't be empty.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    } else if (myloc === "") {
      setLocErr("Location field can't be empty.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    } else if (bio === "") {
      setBioErr("Bio field can't be empty.");
      toast.error(config.errorValidationMsg);
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    } else {
      setLoaderstyle({ display: "block" });
      // const Bdate = moment(birthday).format('DD-MM-YYYY');
      let new_date = moment(birthday, "DD-MM-YYYY").format("DD-MM-YYYY");

      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("location", location);
      formdata.append("bio", bio);
      formdata.append("birthdate", new_date);
      if (doc1) {
        formdata.append("profile_document", doc1);
      }
      if (latlng !== undefined) {
        formdata.append("latitude", latlng.lat);
        formdata.append("longitude", latlng.lng);
      }
      userService
        .updateProfile(formdata)
        .then(function (response) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          alert("Profile updated successfully");
          // getprofiledata();

          window.location.reload();
        })
        .catch(function (error) {
          userService.handleError(error);
        });
    }
  };
  const handleChange = (date: any) => {
    // formData.dob= date;
    // alert(date)
    setStartdate(date);
    // console.log(stratDate)
  };
  const handleChange1 = (date: any) => {
    // formData.dob= date;
    // alert(date)
    setBirthday(date);
    console.log(stratDate);
  };

  const getprofiledata = () => {
    userService
      .my_profile()
      .then(function (response) {
        alert("Profile updated successfully");
        setProfileData(response.data.user);

        var parts: any = response.data.user.birthdate.split("-");
        var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
        const date = moment(mydate).format("D MMMM, YYYY");

        let mydates = new Date(mydate + "(India Standard Time)");
        //  setBirthday(mydates)

        setDob(response.data.user.birthdate);
        setEmail(response.data.user.email);
        console.log("uer--------------------", response.data.user);
        if (response.data.user.birthdate) {
          let dates: any = response.data.user.birthdate;
          var datecheck = moment(dates);
          let valid = datecheck.isValid();
          if (valid === false) {
            const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
            setBirthday(new Date(date));
          } else {
            const date = moment(dates).format("D MMMM, YYYY");
            setBirthday(new Date(date));
          }
        }

        //    console.log("birthdate",response.data.user.birthdate);
        //    var parts:any =response.data.user.birthdate.split('-');

        //    console.log("partss",parts)
        //    var mydate = new Date(parts[2], parts[1]-1, parts[0]);
        //    const date = moment(mydate).format('D MMMM, YYYY');
        //    let mydates = new Date(mydate+"(India Standard Time)")
        //    console.log(mydates);
        //    setBirthday(mydates)
        //    setDob(date)
        setDob(response.data.user.birthdate);
        setName(response.data.user.name);
        setLocation(response.data.user.location);
        setBio(response.data.user.bio);
        setDoc(response.data.user.profile_document);
        setLoaderstyle({ display: "none" });
        //    setStartdate(date1)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  };
  useEffect(() => {
    setLoaderstyle({ display: "block" });
    userService
      .my_profile()
      .then(function (response) {
        //    alert(response.data.user.birthdate)
        setProfileData(response.data.user);
        var parts: any = response.data.user.birthdate.split("-");
        var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
        const date = moment(mydate).format("D MMMM, YYYY");

        let mydates = new Date(mydate + "(India Standard Time)");
        //  setBirthday(mydates)

        setDob(response.data.user.birthdate);
        setEmail(response.data.user.email);
        console.log("uer--------------------", response.data.user);
        if (response.data.user.birthdate) {
          let dates: any = response.data.user.birthdate;
          var datecheck = moment(dates);
          let valid = datecheck.isValid();
          if (valid === false) {
            const date = moment(dates, "DD-MM-YYYY").format("D MMMM, YYYY");
            setBirthday(new Date(date));
          } else {
            const date = moment(dates).format("D MMMM, YYYY");
            setBirthday(new Date(date));
          }
        }
        setName(response.data.user.name);
        setLocation(response.data.user.location);
        setBio(response.data.user.bio);
        setDoc(response.data.user.profile_document);
        let doc_url = response.data.user.profile_document;
        if (doc_url) {
          let doc_url_ary = doc_url.split("/");
          let doc_name = doc_url_ary[doc_url_ary.length - 1];
          setDocName(doc_name);
        }

        setLoaderstyle({ display: "none" });
        //    setStartdate(date1)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });

    //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  useEffect(() => {
    getcards();
    get_favourites();
    let current_date = new Date();
    get_transactions(current_date);
  }, []);

  //   getcards()
  type idParams = {
    id: string;
  };
  let { id } = useParams<idParams>();

  function mark_unmark(provider_id, status, e) {
    e.preventDefault();
    // mark
    let params = {
      provider_id: provider_id,
      status: status,
    };

    setLoaderstyle({ display: "block" });
    userService
      .favMarkUnMark(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        if (status == "F") {
          // alert("Successfully Marked favourite");
        } else {
          // alert("Successfully Marked unfavourite");
        }
        get_favourites();
      })
      .catch(function (error) {
        console.log("errorrrrrr", error);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function get_transactions(date) {
    console.log("monthhh", date.getMonth() + 1);
    console.log("yearrr", date.getFullYear());
    userService
      .getTransactions(
        transactionDate.getMonth() + 1,
        transactionDate.getFullYear()
      )
      .then(function (response) {
        console.log("transactionsss", response.data);
        settransactions(response.data);
      })
      .catch(function (error) {
        console.log("errorrrrrrrr transaction", error);
        userService.handleError(error);
      });
  }

  function download_invoice_api(transaction_id: any) {
    userService
      .downloadInvoice(transaction_id)
      .then(function (response) {
        console.log("download_invoice_api", response);
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log("errorrrrr download invoice", error);
        userService.handleError(error);
      });
  }

  function get_favourites() {
    userService
      .getFavourites(30.704649, 76.717873)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setcards(response.data.cards);
        console.log("fav workerrr", response.data);
        setfavWorker(response.data.favourites);

        // let isbid:any=false;
        // isbid = is_bid(response.data.job)
        // setLogoutpopup(isbid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function getcards() {
    setLoaderstyle({ display: "block" });
    console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    userService
      .card_list()
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log(
          "getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
          response.data
        );
        setcards(response.data.cards);
        console.log(response.data);

        // let isbid:any=false;
        // isbid = is_bid(response.data.job)
        // setLogoutpopup(isbid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function deleteCard(card_id: any) {
    console.log("delete card");

    let result = window.confirm("Are you sure you want to delete this card?");

    if (result) {
      userService
        .deleteCard(card_id)
        .then(function (response) {
          setLoaderstyle({ display: "none" });
          console.log(response.data);
          getcards();
          alert("Card has been deleted successfully");
        })
        .catch(function (error) {
          setLoaderstyle({ display: "none" });
          userService.handleError(error);
        });
    }
  }

  function SetHiredModal() {
    setHiredModal(true);
  }

  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function closemodal() {
    setHiredModal(false);
    setReviewModal(false);
  }

  function onHelpNameChanged(event) {
    setHelpName(
      helpName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setHelpNameError("");
  }
  function onEmailChanged(event) {
    setEmail(event.target.value.replace(/ /g, ""));
    setEmailError("");
  }
  function onSubjectChanged(event) {
    setSbuject(
      subject.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setSbujectError("");
  }
  function onMessageChanged(event) {
    setMessage(
      message.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setMessageError("");
  }

  function onOldPasswordChanged(event) {
    setOldPassword(event.target.value.replace(/ /g, ""));
    setOldPasswordError("");
  }

  function onNewPasswordChanged(event) {
    setNewPassword(event.target.value.replace(/ /g, ""));
    setNewPasswordError("");
  }

  function onConfirmPasswordChanged(event) {
    setConfirmPassword(event.target.value.replace(/ /g, ""));
    setConfirmPasswordError("");
  }

  function onAccountNameChanged(event) {
    setAccountName(
      accountName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setAccountNameError("");
  }

  function onAccountNumberChanged(event) {
    console.log(event.target.value);
    setAccountNumber(
      accountNumber.length == 0
        ? event.target.value.replace(/\D/g, "")
        : event.target.value
    );
    setAccountNumberError("");
  }

  function onCvvChanged(event) {
    setCvv(
      Cvv.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setCvvError("");
  }

  function togglePassword() {
    var tmp = !isrevealPassword;
    setisrevealPassword(tmp);
  }

  function toggleNewPassword() {
    var tmp = !isrevealNewPassword;
    setisrevealNewPassword(tmp);
  }

  function toggleConfirmPassword() {
    var tmp = !isrevealConfirmassword;
    setisrevealConfirmassword(tmp);
  }

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  const handleChangeadd = (e: any) => {
    geocodeByAddress(e.description)
      .then((results: any[]) => getLatLng(results[0]))
      .then(({ lat, lng }: any) => setLatlng({ lat, lng }));
    setAddress(e.description);
  };

  function document_upload(e: any) {
    setDocError("");
    setDoc1(e.target.files[0]);
    console.log(e.target.files[0]);
    var file = e.target.files[0];
    var filename = file.name;
    if (
      file.type == "application/pdf" ||
      file.type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setDocName(filename);
      setDocIsLink(false);
    } else {
      setDocError("Please attach only documents");
    }
  }
  function _handleImageChange(e: any, type: string) {
    // e.preventDefault();
    if (type == "add_image") {
      let file = e[0];
      if (file != undefined) {
        var len = e.length;

        setImagedata(imagedata.concat(e[len - 1]));
      }
    } else if (type == "upload_doc") {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];
      setUploaddoc(file);
      reader.onloadend = () => {
        console.log("onloadend");

        const csv: string = reader.result as string;

        //   setImage(csv)
      };

      reader.readAsDataURL(file);
    } else {
      var filter = imagedata.filter(({ name }) => name !== e.name);
      setImagedata(filter);
    }
  }

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";
  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <section className="profile_detailarea">
        <ToastContainer autoClose={10000} />
        {profileData ? (
          <div className="container">
            <div className="profile_detail_outer">
              <div className="profile_picarea">
                <img
                  src={profileData.profile_image}
                  alt=""
                  className="user_profile_image"
                />
                <div className="change_profilepic">
                  <input
                    type="file"
                    onChange={(e: any) => UpdateProfileImage(e.target.files[0])}
                    accept="image/*"
                  />{" "}
                  Change Profile Picture
                </div>
              </div>
              <h1>{profileData.name}</h1>
              <div className="ratings_area">
                <StarRatings
                  rating={profileData.avgrating}
                  starRatedColor="#f0bf0c"
                  starDimension="20px"
                  starSpacing="1px"
                  starEmptyColor="#cbd3e4"
                  numberOfStars={5}
                  name="rating"
                />
                {profileData.reviews > 0 ? (
                  <span
                    onClick={() => renderReviewModal(profileData._id)}
                    className="reviews_count cursor_pointer"
                  >
                    {" "}
                    ({profileData.reviews}{" "}
                    {profileData.reviews < 2 ? "review" : "reviews"} )
                  </span>
                ) : (
                  <span className="reviews_count">
                    {" "}
                    ({profileData.reviews} reviews)
                  </span>
                )}
              </div>
              <p>Member since {profileData.member_since}</p>
              <div className="row">
                <aside className="col-md-4">
                  <label>Email Address</label>
                  <p title={profileData.email} className="email_overflow">
                    {profileData.email}
                  </p>
                </aside>
                <aside className="col-md-4">
                  <label>Address</label>
                  <p>{profileData.location}</p>
                </aside>
                <aside className="col-md-4">
                  <label>DOB</label>
                  <p>
                    {dob == "Invalid date"
                      ? dateFormat(dob, "d mmm, yyyy")
                      : dateFormat(birthday, "d mmm, yyyy")}
                  </p>
                </aside>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="my_profile_settings">
        <div className="container">
          <div className="row">
            <aside className="col-md-4">
              <div className="profile_tabsarea">
                <h2 className="settings_heading">Settings</h2>
                <ul className="nav nav-tabs" role="tablist">
                  <li role="presentation" className="active">
                    <a
                      href="#my_profile"
                      aria-controls="my_profile"
                      role="tab"
                      data-toggle="tab"
                    >
                      My Profile
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#change_password"
                      aria-controls="change_password"
                      role="tab"
                      data-toggle="tab"
                    >
                      Change Password
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#my_payment_method"
                      aria-controls="my_payment_method"
                      role="tab"
                      data-toggle="tab"
                      onClick={() => {
                        getcards();
                      }}
                    >
                      My Payment Method
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#my_transaction"
                      aria-controls="my_transaction"
                      role="tab"
                      data-toggle="tab"
                    >
                      My Transaction
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#my_favorites"
                      aria-controls="my_favorites"
                      role="tab"
                      data-toggle="tab"
                    >
                      My Favorites
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#help_support"
                      aria-controls="help_support"
                      role="tab"
                      data-toggle="tab"
                    >
                      Help & Support
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      target="_blank"
                      href={"/privacy_policy"}
                      aria-controls="privacy_policy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li role="presentation">
                    <a
                      target="_blank"
                      href={"/terms_conditions"}
                      aria-controls="tems_conditions"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
            <aside className="col-md-8">
              <div className="tab-content new_job_main_box">
                <div
                  role="tabpanel"
                  className="tab-pane active"
                  id="my_profile"
                >
                  <h2>My Profile</h2>
                  <p className="head_content_paragraph">
                    Change your basic information.
                  </p>
                  <form>
                    <div className="form_input_box">
                      <div className="form-group">
                        <label>NAME</label>
                        <input
                          type="text"
                          maxLength={25}
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e: any) => setName(e.target.value)}
                        />
                        {NameErr.length > 0 && (
                          <span style={{ color: "red" }}>{NameErr}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label>DATE OF BIRTH</label>
                        <div className="input_box">
                          <DatePicker
                            selected={birthday}
                            onChange={handleChange1}
                            value={birthday}
                            maxDate={new Date(2006, 11, 24, 10, 33, 30, 0)}
                            relativeSize={true}
                            autoComplete="off"
                            onKeyDown={(e: { preventDefault: () => any }) =>
                              e.preventDefault()
                            }
                            className="form-control"
                            showYearDropdown
                            placeholderText="Select Birthday"
                            dateFormat="dd, MMMM, yyyy"
                          />
                          {/* <DatePicker
                                                selected={stratDate}
                                                onChange={handleChange}
                                                value={stratDate == ""?"May, 2020":stratDate}
                                                dateFormat="MMMM, yyyy"
                                                maxDate={moment().add(20, 'years').toDate()}
                                                minDate={moment().toDate()}
                                                relativeSize={true}
                                                autoComplete="off"
                                                placeholderText="MM/YY"
                                                onKeyDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
                                                className="form-control"
                                            /> */}
                          {/* <input type="text" className="form-control form-control-two" placeholder="Select Date" value="20 September 1920"/>
                                            <img src={date} alt="date"/> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>LOCATION</label>
                        <div className="input_box">
                          <GooglePlacesAutocomplete
                            onSelect={(e: any) => handleChangeadd(e)}
                            inputClassName="form-control form-control-two"
                            initialValue={location}
                          />
                          {locErr.length > 0 && (
                            <span style={{ color: "red" }}>{locErr}</span>
                          )}
                          {/* <input type="text" className="form-control form-control-two" placeholder="Location" value={location} onChange={(e:any)=>setLocation(e.target.value)}/> */}
                          <img src={location_image} alt="location" />
                        </div>
                      </div>
                      <div className="input_form_box">
                        <div className="form-group">
                          <label>Documents</label>
                          <div className="input_content upload_content">
                            {docIsLink ? (
                              <a href={doc} target="_blank">
                                {docName ? docName : doc}
                              </a>
                            ) : (
                              <span>{docName}</span>
                            )}

                            {/* <input type="text" className="form-control" placeholder="Government_id.jpeg" value={doc}/> */}
                            <div className="upload_file-btn-box">
                              <div className="upload-btn-wrapper">
                                <button className="btn">UPLOAD</button>
                                {/* <input type="file"  name="myfile" accept=".xlsx,.xls,.doc, .docx,.pdf" onChange={(e:any)=> setDoc1(e.target.files[0])  } /> */}
                                <input
                                  type="file"
                                  name="myfile"
                                  accept=".xlsx,.xls,.doc, .docx,.pdf"
                                  onChange={(e: any) => document_upload(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {docError.length > 0 && (
                          <span style={{ color: "red" }}>{docError}</span>
                        )}
                        {/* {docError && 
                                            <p className="validation validation-two" >
                                                {docError}
                                            </p>
                                        } */}
                        <p className="validation validation-two">
                          Please upload your verified Government Id or Document.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>BIO</label>
                        <textarea
                          placeholder="Bio"
                          value={bio}
                          onChange={(e: any) => setBio(e.target.value)}
                        >
                          {bio}
                        </textarea>
                        {bioErr.length > 0 && (
                          <span style={{ color: "red" }}>{bioErr}</span>
                        )}
                      </div>
                    </div>
                    <div className="post_button_box">
                      <div className="main_btn">
                        <span onClick={UpdateProfile}>Update</span>
                      </div>
                    </div>
                  </form>
                </div>
                <div role="tabpanel" className="tab-pane" id="change_password">
                  <h2>Change Password</h2>
                  <p className="head_content_paragraph">
                    Fill the information below to change password.
                  </p>
                  <form
                    onSubmit={handleSubmit((e: any, val: any) =>
                      submit(e, val, "changePassword")
                    )}
                  >
                    <div className="form_input_box">
                      <div className="form-group">
                        <label>OLD PASSWORD</label>

                        <div className="relative-boxcustomIcon">
                          <input
                            type="text"
                            name="tmpemail"
                            className="hidden"
                          />
                          <input
                            type="password"
                            name="tmppassword"
                            className="hidden"
                          />
                          <input
                            autoComplete="false"
                            type={isrevealPassword ? "text" : "password"}
                            maxLength={32}
                            value={oldPassword}
                            onChange={onOldPasswordChanged}
                            className="form-control"
                            placeholder="Old Password"
                          />
                          {oldPasswordError.length > 0 && (
                            <span style={{ color: "red" }}>
                              {oldPasswordError}
                            </span>
                          )}
                          {isrevealPassword ? (
                            <i
                              className="fa fa-eye eyeCustomIcon color_blue"
                              aria-hidden="true"
                              onClick={() => togglePassword()}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye eyeCustomIcon"
                              aria-hidden="true"
                              onClick={() => togglePassword()}
                            ></i>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>NEW PASSWORD</label>
                        <div className="relative-boxcustomIcon">
                          <input
                            autoComplete="false"
                            type={isrevealNewPassword ? "text" : "password"}
                            maxLength={32}
                            value={newPassword}
                            onChange={onNewPasswordChanged}
                            className="form-control"
                            placeholder="New Password"
                          />
                          {newPasswordError.length > 0 && (
                            <span style={{ color: "red" }}>
                              {newPasswordError}
                            </span>
                          )}
                          {isrevealNewPassword ? (
                            <i
                              className="fa fa-eye eyeCustomIcon color_blue"
                              aria-hidden="true"
                              onClick={() => toggleNewPassword()}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye eyeCustomIcon"
                              aria-hidden="true"
                              onClick={() => toggleNewPassword()}
                            ></i>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>CONFIRM PASSWORD</label>
                        <div className="relative-boxcustomIcon">
                          <input
                            autoComplete="false"
                            type={isrevealConfirmassword ? "text" : "password"}
                            maxLength={32}
                            value={confirmPassword}
                            onChange={onConfirmPasswordChanged}
                            className="form-control"
                            placeholder="Confirm Password"
                          />
                          {confirmPasswordError.length > 0 && (
                            <span style={{ color: "red" }}>
                              {confirmPasswordError}
                            </span>
                          )}
                          {isrevealConfirmassword ? (
                            <i
                              className="fa fa-eye eyeCustomIcon color_blue"
                              aria-hidden="true"
                              onClick={() => toggleConfirmPassword()}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye eyeCustomIcon"
                              aria-hidden="true"
                              onClick={() => toggleConfirmPassword()}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="post_button_box">
                      <div className="main_btn">
                        <button type="submit" className="disabled">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane"
                  id="my_payment_method"
                >
                  <h2>My Payment Method</h2>
                  <p className="head_content_paragraph">
                    Change your payment method details.
                  </p>
                  <form>
                    <div className="form_input_box">
                      {cards &&
                        cards.length > 0 &&
                        cards.map((item, index) => {
                          return (
                            <div
                              className="payment_method-details border-bottom"
                              key={index}
                            >
                              <p>
                                {/* <img src={mastro} alt="visa"/>  */}
                                **** **** **** {item.last4}
                                <a onClick={() => deleteCard(item._id)}>
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </p>
                            </div>
                          );
                        })}
                      <a
                        data-toggle="modal"
                        data-target="#add_new_card"
                        onClick={SetHiredModal}
                        className="add_new addnew_card"
                      >
                        + Add New Card
                      </a>
                    </div>
                  </form>
                </div>

                <div role="tabpanel" className="tab-pane" id="my_transaction">
                  <h2 className="pull-left">My Transaction</h2>
                  <div className="date_picker pull-right inline-datepicker">
                    <div className="form-group">
                      <div className="date">
                        <div className="input-group input-append date">
                          {/* <input type="text" className="form-control" name="date" id="datepicker" /> */}

                          <DatePicker
                            selected={transactionDate}
                            onChange={handletransactionDateChange}
                            value={transactionDate}
                            maxDate={new Date()}
                            relativeSize={true}
                            autoComplete="off"
                            placeholderText="DD/MM/YY"
                            onKeyDown={(e: { preventDefault: () => any }) =>
                              e.preventDefault()
                            }
                            className="form-control"
                            showMonthYearPicker
                            dateFormat="MMMM, yyyy"
                          />
                          <span className="input-group-addon add-on">
                            <span className="glyphicon glyphicon-calendar"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clear"></div>
                  <div className="my_job_content my-job-content-two my-transaction_box">
                    <ul>
                      {transactions &&
                        transactions.transactions.length > 0 &&
                        transactions.transactions.map((item, index) => {
                          return (
                            <li className="tab_content_box">
                              <h1>
                                <button className="button_borderless">
                                  {item.job_id.title}
                                </button>
                              </h1>
                              <div className="payment-by_box">
                                <h5>
                                  <span className="calender_image">
                                    <img
                                      src={calender_image}
                                      alt="calender_image"
                                    />
                                  </span>
                                  <span>{item.date_of_payment}</span>
                                  <p className="payment-by">
                                    Payment By:<span>{item.customer.name}</span>
                                  </p>
                                </h5>
                              </div>

                              <p className="more_content">
                                {item.job_id.description}
                                {/* <span className="more">
                                                    <button className="button_borderless">more</button>
                                                </span> */}
                              </p>

                              <div className="milestones_box">
                                <h1 className="heading">Stages</h1>
                                <div className="milestones_box_content">
                                  <span className="rouned">1</span>
                                  <h3>
                                    {item.milestone_id.title}{" "}
                                    <span>${item.actual_amount}</span>
                                  </h3>

                                  <p>
                                    {/* After {item.deduction_percent}% dedution */}
                                    <span>
                                      <a
                                        onClick={() =>
                                          download_invoice_api(item._id)
                                        }
                                        download
                                      >
                                        <img
                                          src={download_invoice}
                                          alt="download_invoice"
                                        />{" "}
                                        Download Invoice
                                      </a>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <div role="tabpanel" className="tab-pane" id="my_favorites">
                  <div className="my_favorites-box">
                    <h2>My Favorites</h2>
                    <p className="head_content_paragraph">
                      Your favorite service provider list below.
                    </p>
                    <div className="row top_servies_content">
                      {favWorker &&
                        favWorker.length > 0 &&
                        favWorker.map((item, index) => {
                          let profileurl = favWorker[index].latitude
                            ? "/service_provider_profile/" +
                              favWorker[index]._id +
                              "/" +
                              favWorker[index].latitude +
                              "/" +
                              favWorker[index].longitude
                            : "#";

                          return (
                            <div className="col-sm-4">
                              <div className="main_box">
                                <a href={profileurl}>
                                  <div className="images-box pro_list">
                                    <img
                                      className="fav_provider_image"
                                      src={item.profile_image}
                                      alt="Provider image"
                                    />
                                    {/* <img src={image1} alt="Provider image"/> */}
                                  </div>
                                  <span
                                    onClick={(e) =>
                                      mark_unmark(item._id, "U", e)
                                    }
                                    className="heart-image"
                                  >
                                    {/* <i className="fa fa-heart" aria-hidden="true"></i> */}
                                    <img src={fav_heart} />
                                  </span>
                                  <div className="content_box">
                                    <h1>{item.name}</h1>
                                    <p>
                                      <StarRatings
                                        rating={item.avgrating}
                                        starRatedColor="#f0bf0c"
                                        starDimension="14px"
                                        starSpacing="1px"
                                        starEmptyColor="#cbd3e4"
                                        numberOfStars={5}
                                        name="rating"
                                      />
                                      ({item.reviews}{" "}
                                      {item.reviews < 2 ? "review" : "reviews"})
                                    </p>

                                    <h4>
                                      <span className="left-span">
                                        {item.categories[0]
                                          ? item.categories[0].name
                                          : ""}
                                      </span>
                                      <span className="right-span">
                                        {item.distance != null ? (
                                          <>
                                            <i
                                              className="fa fa-paper-plane"
                                              aria-hidden="true"
                                            ></i>
                                            {item.distance} mi
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                </a>
                              </div>
                            </div>
                          );
                        })}

                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane" id="help_support">
                  <h2>Help & Support</h2>
                  <p className="head_content_paragraph">
                    Feel free to ask questions. We will respond with in 24
                    hours.
                  </p>
                  <form
                    onSubmit={handleSubmit((e: any, val: any) =>
                      submit(e, val, "helpAndSupport")
                    )}
                  >
                    <div className="form_input_box">
                      <div className="form-group">
                        <label>NAME</label>
                        <input
                          type="text"
                          maxLength={20}
                          onChange={onHelpNameChanged}
                          value={helpName}
                          className="form-control"
                          placeholder="Enter Name"
                        />
                        {helpNameError.length > 0 && (
                          <span style={{ color: "red" }}>{helpNameError}</span>
                        )}
                        {/* <Field
                                                name="name"
                                                type="text"
                                                maxLength={20}
                                                component={renderField}
                                                label="NAME"
                                                placeholder="Enter Name"
                                            /> */}
                      </div>
                      <div className="form-group">
                        <label>EMAIL</label>
                        <div className="input_box">
                          <input
                            type="text"
                            maxLength={40}
                            onChange={onEmailChanged}
                            value={email}
                            className="form-control"
                            placeholder="Enter Email Address"
                          />
                        </div>
                        {emailError.length > 0 && (
                          <span style={{ color: "red" }}>{emailError}</span>
                        )}
                        {/* <Field
                                                name="email"
                                                type="text"
                                                maxLength={40}
                                                component={renderField}
                                                label="EMAIL"
                                                placeholder="Enter Email Address"
                                            /> */}
                      </div>
                      <div className="form-group ">
                        <label>SUBJECT</label>
                        <input
                          type="text"
                          maxLength={50}
                          onChange={onSubjectChanged}
                          value={subject}
                          className="form-control "
                          placeholder="Enter Subject "
                        />
                        {subjectError.length > 0 && (
                          <span style={{ color: "red" }}>{subjectError}</span>
                        )}
                        {/* <Field
                                                name="subject"
                                                type="text"
                                                maxLength={50}
                                                component={renderField}
                                                label="SUBJECT"
                                                placeholder="Enter Subject"
                                            /> */}
                      </div>
                      <div className="form-group ">
                        <label>MESSAGE</label>
                        <textarea
                          placeholder="Message"
                          onChange={onMessageChanged}
                          value={message}
                          maxLength={200}
                        ></textarea>
                        {messageError.length > 0 && (
                          <span style={{ color: "red" }}>{messageError}</span>
                        )}
                        {/* <Field
                                                name="message"
                                                type="text"
                                                maxLength={200}
                                                component={renderTextareaField}
                                                label="MESSAGE"
                                                placeholder="Message"
                                            /> */}
                      </div>
                    </div>
                    <div className="post_button_box ">
                      <div className="main_btn ">
                        <button className="button_borderless">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={hiredmodal}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Add New Card
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>
          </h4>
        </div>
        <Elements stripe={stripePromise}>
          <Modal
            ariaHideApp={false}
            isOpen={hiredmodal}
            onRequestClose={closemodal}
            className={"modal-content   new_modal_content add_new_card "}
            contentLabel="Example Modal"
            style={customStyles1}
          >
            <div className="modal-header">
              <h4 className="modal-title">
                Add New Card
                <span>
                  <i
                    onClick={closemodal}
                    className="fa fa-times-circle-o"
                    aria-hidden="true"
                    data-dismiss="modal"
                  ></i>
                </span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="add_new-card_box">
                <div className="form_input_box">
                  <div className="form_input_box">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        CARD HOLDER NAME
                      </label>

                      <input
                        type="text"
                        value={nameHandler}
                        maxLength={20}
                        className="form-control"
                        name="CardHolderName"
                        placeholder="Card Holder Name"
                        onChange={(e) => setNameHandler(e.target.value)}
                      />
                      {nameHandler.length > 0 && (
                        <span style={{ color: "red" }}>{accountNameError}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">CARD NUMBER</label>

                      <PaymentComponent
                        nameHandler={nameHandler}
                        options={options}
                        setLoaderstyle={setLoaderstyle}
                        id={id}
                        getcards={getcards}
                        closemodal={closemodal}
                      />
                      {accountNumberError.length > 0 && (
                        <span style={{ color: "red" }}>
                          {accountNumberError}
                        </span>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </Elements>
      </Modal>

      {/* setReviewModal */}
      <Modal
        ariaHideApp={false}
        isOpen={reviewModal}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Reviews
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul">
            {reviewAry &&
              reviewAry.reviews.length > 0 &&
              reviewAry.reviews.map((item, index) => {
                return (
                  <div className="review_popup">
                    <img src={item.user_id.profile_image} />

                    <div className="review_content">
                      <h3>
                        {item.user_id.name} <span>{item.time_ago}</span>
                      </h3>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starRatedColor="#f0bf0c"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="#cbd3e4"
                        numberOfStars={5}
                        name="rating"
                      />
                      <p>{item.message}</p>
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Myprofile);
