import React, { useEffect, useState } from "react";
import { userService } from "../../_services";
import { Field, reduxForm, SubmissionError, submit } from "redux-form";
import { config } from "../../config/config";
import Modal from "react-modal";

import { Layout } from "../common/Layout";
import close_image from "../../assets/images/close.png";
import profile_image from "../../assets/images/profile_logo.jpg";
import loader_image from "../../assets/images/loader.gif";
import paymentmodal from "../../assets/images/payment-modal.png";
import { jobsService } from "../../_services";

import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bid_image from "../../assets/images/bid_image.png";
import mastro from "../../assets/images/mastro.png";
import visa from "../../assets/images/visa.png";
import adam_message_image from "../../assets/images/adam_message_image.png";
import chat_image from "../../assets/images/chat_image.png";
import StarRatings from "react-star-ratings";
import PaymentComponent from "./PaymentComponent";
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(
//   "pk_test_51H54EAB80x5swfvw4xQGhJwMZ2vXqnLHS59kGho6u2Zm4vXHF4KM8CFwVSaxQMqfl2cHJGQ2s7FLZDiRXRPiKWMX00HqaWsbV7"
// );
const stripePromise = loadStripe(
  "pk_live_51H54EAB80x5swfvwsR108Fog1f668MEBEaaKsbbbky0wVWu9maqjuZhaR3fr3Cvl2NCKA9qkXr0HFJN7ZXyUyqQh00exEoECBZ"
);

interface fieldInterface {
  input: any;
  label: string;
  type: string;
  image: string;
  id: string;
  stratDate: any;
  handleChange: any;
  placeholder: string;
  value: string;
  contentEditable: boolean;
  selectedValues: [];
  maxLength: number;
  meta: {
    touched: boolean;
    error: string;
  };
}

const renderField = ({
  input,
  label,
  type,
  id,
  value,
  contentEditable,
  placeholder,
  maxLength,
  meta: { touched, error },
}: fieldInterface) => (
  <div className="form-group">
    {label && <label>{label}</label>}
    <div className="input_content">
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        className="form-control"
        id={id}
        placeholder={placeholder}
      />
      {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  </div>
);

const Payment = (props: any) => {
  const [hiredmodal, setHiredModal] = useState(false);
  const [setthanks, setThanksModal] = useState(false);
  const [stratDate, setStartdate] = useState("");
  const [showDOBError, setDOBError] = useState(false);
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const [jobDetail, setJobdetail] = useState() as any;
  const [Bid, setBid] = useState() as any;

  const [cards, setcards] = useState([]) as any;
  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [CvvError, setCvvError] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [Cvv, setCvv] = useState("");
  const [selectedCard, setselectedCard] = useState() as any;
  const [selectedCardLast4, setselectedCardLast4] = useState() as any;
  const [cvvModal, setCvvModal] = useState(false);

  const [paymentsuccess, setPaymentsucess] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [toggleStripe, setToggleStripe] = useState(true);
  const [nameHandler, setNameHandler] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [navigateToJobModal, setNavigateToJobModal] = useState(false);
  const [priceDetails, setPriceDetails] = useState({
    totalAmount: 0,
    tendrFee: 0,
    taxes: {
      providerTax: 0,
      tendrTax: 0,
    },
  }) as any;
  const history = useHistory();
  useEffect(() => {
    if (!clientSecret && toggleStripe) {
      const getClientSecretFn = async () => {
        userService
          .getClientSecret()
          .then((result) => {
            setClientSecret(result.data.client_secret);
          })
          .then(() => {
            setToggleStripe(true);
          });
      };

      getClientSecretFn();
    }
  }, [toggleStripe]);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };
  console.log({ clientSecret });
  const submit = (e: any, fields: any, type: String) => {
    e.preventDefault();
    if (type == "addCard") {
      // if (nameHandler.length == 0) {
      //   setAccountNameError("Card Holder Name field can't be empty.");
      // } else if (accountNumber.length == 0) {
      //   setAccountNumberError("Card Number field can't be empty.");
      // } else if (!/^\d+$/.test(accountNumber)) {
      //   setAccountNumberError("Card Number should only contains numbers");
      // }

      if (!stratDate) {
        setDOBError(true);
      } else if (Cvv.length < 3) {
        setCvvError("CVV must be 3 or 4 digits.");
      } else if (!/^\d+$/.test(Cvv)) {
        setCvvError("CVV field should only contains numbers.");
      } else {
        console.log("form submit");

        let params = {
          card_number: accountNumber,
          exp_month: "12",
          exp_year: "2022",
          cvc: Cvv,
        };

        setLoaderstyle({ display: "block" });
        userService
          .save_card(params)
          .then(function (response) {
            setLoaderstyle({ display: "none" });
            alert("Card added Successfully.");
            closemodal();
            getcards();
            // window.location.href = "/invite_user/"+job_id;
          })
          .catch(function (error) {
            console.log("errorrrrrr", error);
            setLoaderstyle({ display: "none" });
            userService.handleError(error);
            // alert("Your card details are incorrect.");
          });
      }
    }
  };

  function getcards() {
    setLoaderstyle({ display: "block" });
    console.log("getdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    userService
      .card_list()
      .then(function (response) {
        console.log({ rescard: response.data });
        setLoaderstyle({ display: "none" });
        setcards(response.data.cards);
        if (response.data.cards.length > 0) {
          setselectedCard(response.data.cards[0]._id);
          setselectedCardLast4(response.data.cards[0].last4);
        }

        // let isbid:any=false;
        // isbid = is_bid(response.data.job)
        // setLogoutpopup(isbid)
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function getData() {
    // setLoaderstyle({display:"block"})
    // console.log("getdata");
    // console.log(id);
    // jobsService.getJob(id as string)
    // .then(function (response) {
    //     setLoaderstyle({display:"none"})
    //     // setJobdetail(response.data.job)
    //     console.log(response.data.job);
    //     // let isbid:any=false;
    //     // isbid = is_bid(response.data.job)
    //     // setLogoutpopup(isbid)

    // }).catch(function(error){
    //     setLoaderstyle({display:"none"})
    // });
    console.log(
      "idddddddddddddddddddd get data aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
      id
    );

    jobsService
      .bidDetail(id as string)
      .then(function (response) {
        console.log("responseeeeeeeeeeeeeeeee");
        console.log(response.data.bid);
        setBid(response.data.bid);
        console.log("=============================response", response.data);
        setJobdetail(response.data.bid.job_id);
        userService
          .customerPriceDetail({ data: response.data.bid.milestones[0].price })
          .then((response) => {
            setPriceDetails(response.data);
            console.log("pricing response", response.data);
          });
        setLoaderstyle({ display: "none" });
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function SetHiredModal() {
    setHiredModal(true);
  }

  function SetThanks() {
    // check if any card selected or not
    console.log(selectedCard);
    if (selectedCard == undefined) {
      alert("Please add card first");
      return;
    }
    setCvvModal(true);
  }

  function make_payment() {
    setPaymentLoading(true);
    console.log("biddd", Bid);

    let params = {
      bid_id: Bid._id,
      job_id: Bid.job_id._id,
      status: "ACC",
      bid_price: Bid.bid_price,
      bidder_id: Bid.user_id._id,
      stripe_card_id: selectedCard,
    };
    // const formData = new URLSearchParams();
    // formData.append('bid_id',Bid._id);
    // formData.append('job_id',Bid.job_id._id);
    // formData.append('status',"ACC");
    // formData.append('bid_price', Bid.bid_price);
    // formData.append('bidder_id',Bid.user_id._id);
    // formData.append('stripe_card_id',selectedCard );
    // console.log(formData);
    // console.log(formData.toString());
    jobsService
      .acceptRejectBid(params)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("bid accepted");
        console.log(response);
        closemodal();
        // alert("Bid Accepted Successfully");
        // setPaymentsucess(true)
        setPaymentLoading(false);
        setThanksModal(true);
        setTimeout(function () {
          window.location.href = "/my_jobs";
        }, 3000);
      })
      .catch(function (error) {
        setPaymentLoading(false);
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
        closemodal();
        setNavigateToJobModal(true);
      });
  }

  function select_card(item) {
    setselectedCard(item._id);
    setselectedCardLast4(item.last4);
  }

  function closemodal() {
    setHiredModal(false);
    setCvvModal(false);
    setThanksModal(false);
    setPaymentsucess(false);
    setNavigateToJobModal(false);
  }

  function onAccountNameChanged(event) {
    setAccountName(
      accountName.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setAccountNameError("");
  }

  function onAccountNumberChanged(event) {
    setAccountNumber(
      accountNumber.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setAccountNumberError("");
  }

  function onCvvChanged(event) {
    setCvv(
      Cvv.length == 0
        ? event.target.value.replace(/ /g, "")
        : event.target.value
    );
    setCvvError("");
  }

  type idParams = {
    id: string;
  };
  let { id } = useParams<idParams>();

  console.log("iddddddddddddddddddd", id);
  useEffect(() => {
    getData();
    getcards();
    //    setProfile(JSON.parse(localStorage.getItem("user") as string).image)
  }, []);

  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  const handleChange = (date: any) => {
    // formData.dob= date;
    setStartdate(date);
    console.log(stratDate);
    setDOBError(false);
  };

  const { error, className, handleSubmit, pristine, reset, submitting } = props;
  console.log({ sc: selectedCard?._id });
  return (
    <section className="payment-section-area">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="payment_box">
              <div className="bid_message_content">
                <ul>
                  {jobDetail ? (
                    <li>
                      <div className="left_content">
                        <img
                          src={Bid.user_id.profile_image}
                          alt={Bid.user_id.name}
                        />
                        <h2>{Bid.user_id.name}</h2>
                        <p>
                          <StarRatings
                            rating={Bid.user_id.avgrating}
                            starRatedColor="#f0bf0c"
                            starDimension="14px"
                            starSpacing="1px"
                            starEmptyColor="#cbd3e4"
                            numberOfStars={5}
                            name="rating"
                          />
                          <a>
                            {" "}
                            ({Bid.user_id.reviews}{" "}
                            {Bid.user_id.reviews < 2 ? "review" : "reviews"} ){" "}
                          </a>
                        </p>

                        <div className="span_btn_style">
                          {jobDetail &&
                            Bid &&
                            Bid.user_id.categories.map((item, index) => {
                              return <span>{item.category.name}</span>;
                            })}
                        </div>
                      </div>
                      <div className="right_content">
                        <p className="green_text"> {jobDetail.job_status}</p>
                      </div>
                      <div className="clear"></div>
                    </li>
                  ) : (
                    <li></li>
                  )}
                  <li>
                    <div className="message">
                      <h4>Bid</h4>
                      {jobDetail && Bid && Bid.bid_message ? (
                        <p className="break_word">{Bid.bid_message}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="message mileston">
                      <h4>Stages</h4>

                      {jobDetail &&
                        Bid &&
                        Bid.milestones.map((item, index) => {
                          return (
                            <div className="mileston_list">
                              <span className="list_span">{index + 1}</span>
                              <p>
                                {item.title}{" "}
                                <span>
                                  ${parseFloat(item.price).toFixed(2)}
                                </span>
                              </p>
                              <p className="break_word">{item.description}</p>
                            </div>
                          );
                        })}
                    </div>
                  </li>
                  <li>
                    <div className="message cost">
                      <h4>Cost</h4>
                      {jobDetail && (
                        <p> ${parseFloat(Bid.bid_price).toFixed(2)}</p>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="payment_box makepayment-box">
              <div className="make_payment_main">
                <div className="payment-header">
                  <h1>Make Payment</h1>
                </div>
                <div className="make_payment">
                  {jobDetail && (
                    <div className="make_payment_content">
                      <h2>
                        You have accepted a bid sent from{" "}
                        <span>“{Bid.user_id.name}”</span> on cost{" "}
                        <span>“${Bid.bid_price}”</span>.
                      </h2>
                      <div className="radio_button_box">
                        <label className="radio_box">
                          Deposit the amount of the first stage.
                          <input type="checkbox" checked />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {Bid && (
                        <>
                          <h5>
                            {Bid.milestones[0].title}
                            <span>
                              ${parseFloat(Bid.milestones[0].price).toFixed(2)}
                            </span>
                          </h5>
                          <h5>
                            Service Fee (5%)
                            <span>${(priceDetails?.tendrFee).toFixed(2)}</span>
                          </h5>
                          <h5>
                            Tax (5% GST)
                            <span>
                              $
                              {(
                                priceDetails?.taxes.providerTax +
                                priceDetails?.taxes?.tendrTax
                              ).toFixed(2)}
                            </span>
                          </h5>
                          <h5>
                            Total Amount Due
                            <span>
                              ${(priceDetails?.totalAmount).toFixed(2)}
                            </span>
                          </h5>
                        </>
                      )}

                      <p className="red_text">
                        * Your funds will be transferred to the SP when you
                        approve the completion of a stage.
                      </p>
                    </div>
                  )}

                  <div className="payment_method_content">
                    <h1>Payment Method</h1>
                    <ul>
                      {cards &&
                        cards.map((item, index) => {
                          return (
                            <li>
                              <div className="payment_content">
                                <p>
                                  <img src={visa} alt="visa" />
                                  **** **** **** {item.last4}
                                </p>
                              </div>
                              <div className="payment_chekbox">
                                <div className="radio_button_box">
                                  <label className="radio_box">
                                    <input
                                      type="radio"
                                      checked={
                                        selectedCard == item._id ? true : false
                                      }
                                      name="radio"
                                      onClick={() => {
                                        return (
                                          select_card(item),
                                          console.log({ item })
                                        );
                                      }}
                                    />
                                    <span
                                      className="checkmark"
                                      onClick={() => select_card(item)}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                              <div className="clear"></div>
                            </li>
                          );
                        })}
                    </ul>
                    <a
                      data-toggle="modal"
                      onClick={SetHiredModal}
                      data-target="#add_new_card"
                      className="add_new"
                    >
                      + Add New Card
                    </a>
                    <div className="tab_bottom_button">
                      <a
                        data-toggle="modal"
                        onClick={SetThanks}
                        data-target="#payment_success"
                      >
                        Proceed to payment
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Create Customer Welcome Modal */}
      <Elements stripe={stripePromise}>
        <Modal
          ariaHideApp={false}
          isOpen={hiredmodal}
          onRequestClose={closemodal}
          className={"modal-content   new_modal_content add_new_card "}
          contentLabel="Example Modal"
          style={customStyles1}
        >
          <div className="modal-header">
            <h4 className="modal-title">
              Add New Card
              <span>
                <i
                  onClick={closemodal}
                  className="fa fa-times-circle-o"
                  aria-hidden="true"
                  data-dismiss="modal"
                ></i>
              </span>
            </h4>
          </div>
          <div className="modal-body">
            <div className="add_new-card_box">
              <div className="form_input_box">
                <div className="form_input_box">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">CARD HOLDER NAME</label>

                    <input
                      type="text"
                      value={nameHandler}
                      maxLength={20}
                      className="form-control"
                      name="CardHolderName"
                      placeholder="Card Holder Name"
                      onChange={(e) => setNameHandler(e.target.value)}
                    />
                    {nameHandler.length > 0 && (
                      <span style={{ color: "red" }}>{accountNameError}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">CARD NUMBER</label>

                    <PaymentComponent
                      nameHandler={nameHandler}
                      options={options}
                      setLoaderstyle={setLoaderstyle}
                      id={id}
                      getcards={getcards}
                      closemodal={closemodal}
                    />
                    {accountNumberError.length > 0 && (
                      <span style={{ color: "red" }}>{accountNumberError}</span>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Elements>
      <Modal
        ariaHideApp={false}
        isOpen={setthanks}
        onRequestClose={closemodal}
        className={"modal-content    "}
        portalClassName={"payment_success"}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <span data-dismiss="modal" className="cross">
            <i
              onClick={closemodal}
              className="fa fa-times-circle-o"
              aria-hidden="true"
            ></i>
          </span>
        </div>
        <div className="modal-body">
          <img src={paymentmodal} alt="payment-modal" />
          {Bid && (
            <p>
              You have hired <span>“{Bid.user_id.name}”</span>
              <br />
              successfully
            </p>
          )}
        </div>
      </Modal>
      {/* cvvModal */}
      <Modal
        ariaHideApp={false}
        isOpen={cvvModal}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {/* Enter Cvv (Confirm Payment) */}
            Confirm Payment
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>

        <div className="modal-body">
          {Bid && (
            <div className="milestonePopup">
              <h3 className="color_blue_text">
                {" "}
                Stage Amount: ${(priceDetails?.totalAmount).toFixed(2)}
              </h3>
              {/* <input
                type="password"
                placeholder="CVV"
                minLength={3}
                maxLength={4}
                value={Cvv}
                onChange={(e: any) => onCvvChanged(e)}
              /> */}
              <p>Pay with credit card ending in: {selectedCardLast4}</p>
              <button
                className="btnStyle"
                onClick={() => make_payment()}
                type="submit"
                disabled={paymentLoading}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={navigateToJobModal}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            {/* Enter Cvv (Confirm Payment) */}
            View Job
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>

        <div className="modal-body">
          {Bid && (
            <div className="milestonePopup">
              <h3 className="color_blue_text">
                {" "}
                Navigate to view the job details.
              </h3>

              <button
                className="btnStyle"
                onClick={() =>
                  // console.log(Bid.job_id._id)
                  history.push(`/bid_detail/${Bid.job_id._id}`)
                }
                type="submit"
                disabled={paymentLoading}
              >
                View Job
              </button>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        ariaHideApp={false}
        isOpen={paymentsuccess}
        onRequestClose={closemodal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Enter Cvv
            <span>
              <i
                onClick={closemodal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>

        <div className="modal-body">
          <div className="milestonePopup">
            <h3 className="color_blue_text">Payment released successfully.</h3>
          </div>
        </div>
      </Modal>
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={loader_image} />
        </div>
      </div>
    </section>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(Payment);
