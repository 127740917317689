import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import bid_image from "../../assets/images/bid_image.png";
import chat_image from "../../assets/images/chat_image.png";
import slider_image from "../../assets/images/slider_image.png";
import { config } from "../../config/config";
import Modal from "react-modal";
import { jobsService } from "../../_services";
import { userService } from "../../_services";

import StarRatings from "react-star-ratings";
import { red } from "@material-ui/core/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
interface fieldInterface {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: boolean;
    error: string;
  };
}

const JobDetails = (props: any) => {
  const [loader, setLoader] = useState(0);
  const [logoutpopup, setLogoutpopup] = useState(false);
  const [jobDetail, setJobdetail] = useState() as any;
  const [loaderStyle, setLoaderstyle] = useState({ display: "none" });
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("id");
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewAry, setReviewAry] = useState("") as any;

  // console.log(location.search);
  useEffect(() => {
    getData();
  }, []);
  const customStyles1 = {
    overlay: {
      display: "block",
      paddingRight: "15px",
      backgroundColor: "rgba(51,51,51,0.8)",
      zIndex: 99,
    },

    content: {
      position: "",
      top: "",
      right: "",
      bottom: "",
      left: "",
    },
  };

  function getData() {
    setLoaderstyle({ display: "block" });
    jobsService
      .getJob(myParam as string)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        setJobdetail(response.data.job);
        console.log("----job detail", response.data.job);
        let isbid: any = false;
        isbid = is_bid(response.data.job);
        setLogoutpopup(isbid);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }
  function renderReviewModal(user_id) {
    setLoaderstyle({ display: "block" });
    userService
      .userReviews(user_id)
      .then(function (response) {
        setLoaderstyle({ display: "none" });
        console.log("reviewsss", response.data);
        setReviewAry(response.data);
        setReviewModal(true);
      })
      .catch(function (error) {
        setLoaderstyle({ display: "none" });
        userService.handleError(error);
      });
  }

  function open_user_detail_page(job_id: any, user_id: any) {
    window.location.href = "/customerjobdetail/" + job_id + "/" + user_id;
  }
  function is_bid(job: any) {
    let jobdetail: any = job;
    let is_present = false;
    if (jobdetail && jobdetail.bids.length > 0) {
      jobdetail.bids.map((item, index) => {
        if (
          item.user_id._id ==
          JSON.parse(localStorage.getItem("user") as string)._id
        ) {
          return (is_present = true);
        } else {
          return false;
        }
      });
    }
    if (is_present == false && jobdetail.hiredBidder) {
      if (
        jobdetail.hiredBidder._id ==
        JSON.parse(localStorage.getItem("user") as string)._id
      ) {
        is_present = true;
      }
    }
    return is_present;
  }

  const getBidPrice = (job) => {
    let bidPrice = 0;
    if (job && job.bids.length > 0) {
      job.bids.map((item, index) => {
        if (
          item.user_id._id ==
          JSON.parse(localStorage.getItem("user") as string)._id
        ) {
          bidPrice = item.bid_price;
        }
      });
    }
    return bidPrice;
  };
  const getStages = (job) => {
    let stages = 0;
    if (job && job.bids.length > 0) {
      job.bids.map((item, index) => {
        if (
          item.user_id._id ==
          JSON.parse(localStorage.getItem("user") as string)._id
        ) {
          stages = item.milestones.length;
        }
      });
    }
    return stages;
  };

  function Logout() {
    localStorage.clear();
    window.location.href = `${config.appurl}`;
  }

  function goToSubmitBid(item: any) {
    window.location.href = "submit_bid?id=" + item._id;
  }

  function Logoutpopup() {
    setLogoutpopup(true);
  }

  function closeModal() {
    setLogoutpopup(false);
    setReviewModal(false);
  }
  function getFirstThreeLetters(str) {
    return str.slice(0, 3);
  }

  const downloadFile = (url) => {
    console.log(url);
    const fileUrl = `https://tendr.live:8443/attachments/${url}`;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = `${url}`; // Set the desired file name

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const {
    error,
    classNameNameName,
    handleSubmit,
    pristine,
    reset,
    submitting,
  } = props;
  return (
    <div className="full_body">
      {jobDetail && jobDetail.job_images.length > 0 ? (
        <section
          className="preview_post_banner_area"
          style={{
            backgroundImage: `url(${jobDetail.job_images[0].job_image})`,
          }}
        ></section>
      ) : (
        <section className=""></section>
      )}
      {jobDetail && (
        <section className="slider_preview_post">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div
                className={
                  jobDetail && jobDetail.job_images.length > 0
                    ? "col-md-4 slider-box"
                    : "col-md-4 slider-box without_image"
                }
              >
                {jobDetail && jobDetail.job_images.length > 0 ? (
                  <Carousel autoPlay>
                    {jobDetail.job_images.map((item, index) => {
                      console.log("item", item);
                      return (
                        <div>
                          <img
                            src={item.job_image}
                            alt="slider_image.png"
                            style={{ width: "100%" }}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <div></div>
                )}

                <div className="all_bid_box" style={{ paddingBottom: "0px" }}>
                  <div className="heading">
                    <h1>About Customer </h1>
                  </div>
                  <div className="all_bid_content">
                    <ul>
                      <li>
                        <div className="content-box job-detial-content-box new-job-details no_chat">
                          <div className="left-side-content job-detils-left-box">
                            <img
                              src={
                                jobDetail
                                  ? jobDetail.user_id.profile_image
                                  : bid_image
                              }
                              alt="bid_image"
                            />
                            <h1
                              className="cursor_pointer"
                              onClick={() =>
                                open_user_detail_page(
                                  jobDetail._id,
                                  jobDetail.user_id._id
                                )
                              }
                            >
                              {jobDetail.user_id.name}
                            </h1>
                            <p>
                              {" "}
                              <StarRatings
                                rating={jobDetail.user_id.avgrating}
                                starRatedColor="#f0bf0c"
                                starDimension="14px"
                                starSpacing="1px"
                                starEmptyColor="#cbd3e4"
                                numberOfStars={5}
                                name="rating"
                              />
                              <button
                                onClick={() =>
                                  renderReviewModal(jobDetail.user_id._id)
                                }
                                className="button_borderless"
                              >
                                ({jobDetail.user_id.reviews}{" "}
                                {jobDetail.user_id.reviews < 2
                                  ? "review"
                                  : "reviews"}{" "}
                                )
                              </button>
                            </p>
                          </div>
                          <div className="left-side-content text-center">
                            <h1>
                              <span className="job-posted">Jobs Posted</span>
                            </h1>
                            <p>
                              <span className="job-posted-10">
                                {jobDetail.jobs_posted}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="clear"></div>
                      </li>
                    </ul>
                  </div>
                  {!logoutpopup == false && (
                    <div className="progress_bottom">
                      <p>Bid Submitted</p>
                    </div>
                  )}
                </div>
                {logoutpopup == false && (
                  <div className="job-details-button-box">
                    <div className="main_btn">
                      <button
                        className="button_borderless"
                        onClick={() => goToSubmitBid(jobDetail)}
                      >
                        Submit Bid
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-6 tab-content new_job_main_box">
                <div className="slider_right_content">
                  <h2 className="posted">
                    Posted <span>: {jobDetail.time_ago}</span>
                  </h2>
                  <h1>{jobDetail.title}</h1>
                  <ul>
                    <li>
                      <p>
                        Service Category:<span>{jobDetail.category.name}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Start Date & Time:
                        <span>
                          {jobDetail.start_date} &{jobDetail.start_time}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        End Date & Time:
                        <span>
                          {jobDetail.end_date} & {jobDetail.end_time}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Budget:<span>${jobDetail.budget}</span>
                      </p>
                    </li>
                    <li className="location-job">
                      <p className="confirm_post_location">
                        {jobDetail.isShowLocation
                          ? "Location:"
                          : "Postal Code:"}
                        <span>
                          {jobDetail.isShowLocation
                            ? jobDetail.location
                            : getFirstThreeLetters(jobDetail.city)}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p>
                        Bids:
                        <span>{jobDetail.bids.length}</span>
                      </p>
                    </li>
                    {is_bid(jobDetail) && (
                      <>
                        <li>
                          <p>
                            Your bid:
                            <span>${getBidPrice(jobDetail)}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            Total Stages:
                            <span>{getStages(jobDetail)}</span>
                          </p>
                        </li>
                      </>
                    )}

                    <li>
                      <p className="description">Description:</p>
                      <p className="description_content">
                        {jobDetail.description}
                      </p>
                    </li>
                    {jobDetail?.attachment != "null" ? (
                      <li>
                        <p>
                          Attachment
                          <span>
                            {jobDetail?.attachment && (
                              <div>
                                <button
                                  onClick={() =>
                                    downloadFile(
                                      jobDetail.attachment?.split("/").pop()
                                    )
                                  }
                                >
                                  {jobDetail.attachment?.split("/").pop()}
                                </button>
                              </div>
                            )}
                            {/* {jobDetail?.attachment.map((item, index) => {
                              // console.log("item", item);
                              return (
                                <div key={index}>
                                  <a href={item} target="_blank">
                                    {item?.split("/").pop()}
                                  </a>
                                </div>
                              );
                            })} */}
                          </span>
                        </p>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </section>
      )}
      <div className="loader-box" style={loaderStyle}>
        <div className="loader-image">
          <img src={require("../../assets/images/loader.gif")} />
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={reviewModal}
        onRequestClose={closeModal}
        className={"modal-content   new_modal_content add_new_card "}
        contentLabel="Example Modal"
        style={customStyles1}
      >
        <div className="modal-header">
          <h4 className="modal-title">
            Reviews
            <span>
              <i
                onClick={closeModal}
                className="fa fa-times-circle-o"
                aria-hidden="true"
                data-dismiss="modal"
              ></i>
            </span>{" "}
          </h4>
        </div>
        <div className="modal-body">
          <ul className="review_modal_ul">
            {reviewAry &&
              reviewAry.reviews.length > 0 &&
              reviewAry.reviews.map((item, index) => {
                return (
                  <div className="review_popup">
                    <img src={item.user_id.profile_image} />

                    <div className="review_content">
                      <h3>
                        {item.user_id.name} <span>{item.time_ago}</span>
                      </h3>
                      <StarRatings
                        rating={parseInt(item.rating)}
                        starRatedColor="#f0bf0c"
                        starDimension="20px"
                        starSpacing="1px"
                        starEmptyColor="#cbd3e4"
                        numberOfStars={5}
                        name="rating"
                      />
                      <p>{item.message}</p>
                    </div>
                  </div>
                );
              })}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "loginForm", // a unique identifier for this form
})(JobDetails);
